<template>
  <div class="slides">
    <transition-group name="fade" tag="div">
      <div v-for="i in [currentIndex]" :key="i">
        <img :src="currentImg" />
      </div>
    </transition-group>
    <!-- <a class="prev" @click="prev" href="#">&#10094; Previous</a>
    <a class="next" @click="next" href="#">&#10095; Next</a> -->
  </div>
</template>
<script>
export default {
  name: "Slider",
  data() {
    return {
      images: [
        require("../../assets/Gallery/Rockcitymall_new-4.jpg"),
        require("../../assets/Gallery/Rockcitymall_new-7.jpg"),
        require("../../assets/backgroundImages/rockcity mall-245.jpg"),
        require("../../assets/backgroundImages/rockcity mall-232.jpg"),
        require("../../assets/backgroundImages/rockcity mall-191.jpg"),
        require("../../assets/backgroundImages/rockcity mall-165.jpg"),
        require("../../assets/backgroundImages/rockcity mall-163.jpg"),
        require("../../assets/backgroundImages/rockcity mall-152.jpg"),
        require("../../assets/backgroundImages/rockcity mall-134.jpg"),
        require("../../assets/backgroundImages/rockcity mall-122.jpg"),
        require("../../assets/backgroundImages/rockcity mall-35.jpg"),
      ],
      timer: null,
      currentIndex: 0,
    };
  },

  //   mounted: function () {
  //     this.startSlide();
  //   },

  methods: {
    startSlide: function () {
      this.timer = setInterval(this.next, 4000);
    },

    next: function () {
      this.currentIndex += 1;
    },
    prev: function () {
      this.currentIndex -= 1;
    },
  },
  mounted: function () {
    this.startSlide();
  },
  computed: {
    currentImg: function () {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width: 100%;
  opacity: 0;
}

img {
  /* height: 100vh; */
  width: 100%;
  margin-top: -100px;
}
.slides {
  height: 100%;
  contain: content;
}
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
@media screen and (max-width: 1000px) {
  img {
  height: 80vh;
  width: unset;
}
}
@media screen and (max-width: 720px) {
  .slides {
    height: 80vh;
  }
  img {
    height: 80vh;
    width: unset;
    margin: auto;
  }
}
</style>