<template>
  <div>
    <div>
      <h2>News And Updates</h2>
    </div>
    <div class="container">
      <div id="myCarousel" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
          <li data-target="#myCarousel" data-slide-to="1"></li>
          <li data-target="#myCarousel" data-slide-to="2"></li>
          <li data-target="#myCarousel" data-slide-to="3"></li>
          <li data-target="#myCarousel" data-slide-to="4"></li>
          <li data-target="#myCarousel" data-slide-to="5"></li>
        </ol>

        <div class="carousel-inner">
          <div class="item active image-1">
            <div class="Text">
              <div>
                <h3>{{ Slide1.header }}</h3>
                <p>
                  {{ Slide1.description }}
                </p>
              </div>
            </div>
          </div>

          <div class="item image-2">
            <div class="Text">
              <div>
                <h3>{{ Slide2.header }}</h3>
                <p>
                  {{ Slide2.description }}
                </p>
              </div>
            </div>
          </div>

          <div class="item image-3">
            <div class="Text">
              <div>
                <h3>{{ Slide3.header }}</h3>
                <p>
                  {{ Slide3.description }}
                </p>
              </div>
            </div>
          </div>
          <div class="item image-4">
            <div class="Text">
              <div>
                <h3>{{ Slide4.header }}</h3>
                <p>
                  {{ Slide4.description }}
                </p>
              </div>
            </div>
          </div>
          <div class="item image-5">
            <div class="Text">
              <div>
                <h3>{{ Slide5.header }}</h3>
                <p>
                  {{ Slide5.description }}
                </p>
              </div>
            </div>
          </div>
          <div class="item image-6">
            <div class="Text">
              <div>
                <h3>{{ Slide6.header }}</h3>
                <p>
                  {{ Slide6.description }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <a class="left carousel-control" href="#myCarousel" data-slide="prev">
          <span class="glyphicon"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              /></svg
          ></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="right carousel-control" href="#myCarousel" data-slide="next">
          <span class="glyphicon"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              /></svg
          ></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slides",
  data() {
    return {
      Slide1: {
        header: "Meet Our New Modern Automated Parking System",
        description:
          "We are happy to announce that our new Modern Automated Parking system is now operational. It is very intuitive and easy to use, even on your own, however, if you need some help, our team will be right beside you to assist you. For the first  our you will get to park for free and only pay Tshs.1,000/ for each hour after that. We hope to see you soon.",
      },
      Slide2: {
        header: "The Multi-Purpose Hall is Now Open",
        description:
          "We are excited to announce that the Multi-Purpose Hall is Open, now under new management, we renovated with a friendly ambience, with different packages to pick and choose from.",
      },
      Slide3: {
        header: "New Faces, Meet Our New Customer Service Team",
        description:
          "In order to better your experience on your next visit, we have added some new faces on the team. Our team will be there for you. Whenever you need some help with anything, be sure to checkin with one of our customer service representatives.",
      },
      Slide4: {
        header: "The Information Desk Now Operational",
        description:
          "We have set up an information desk to help you get serviced faster and more conveniently. At the information desk you will find all the information you need, for example, how to get around, finding the different services within the mall and many, many more.",
      },
      Slide5: {
        header: "Cinema Now Open",
        description:
          "We are happy to inform you that the Cinema is Now Open. With new theatrical releases just in time for the holidays, it's a good time to take a trip to the cinema.",
      },
      Slide6: {
        header: "Stay Safe",
        description:
          "We continue to encourage you to stay safe, and take all necessary safety measures to keep yourself and your loved ones safe.",
      },
    };
  },
};
</script>

<style scoped>
.carousel-control {
  opacity: unset;
  background-image: none;
}
.glyphicon {
  margin-top: 33vh;
  box-shadow: none;
}
svg {
  width: 32px;
  height: 32px;
}
a {
  height: 0px;
}
.item {
  padding-top: 180px;
  padding-bottom: 180px;
  height: 100%;
  line-height: 30px;
  text-align: center;
  margin: auto;
}
.image-1 {
  background-image: url("../../assets/upadteImages/Parking.webp");
  background-size: cover;
  background-position: center;
}
.image-2 {
  background-position: center;
  background-image: url("../../assets/upadteImages/Multipurpose.webp");
  background-size: cover;
}
.image-3 {
  background-image: url("../../assets/upadteImages/NewFaces.webp");
  background-size: cover;
  background-position: center;
}
.image-4 {
  background-image: url("../../assets/upadteImages/Desk.webp");
  background-size: cover;
  background-position: center;
}
.image-5 {
  background-image: url("../../assets/upadteImages/cinema.webp");
  background-size: cover;
  background-position: center;
}
.image-6 {
  background-image: url("../../assets/upadteImages/StaySafe.webp");
  background-size: cover;
  background-position: center;
}
.Text {
  margin: auto;
  background-color: #fff;
  width: 70%;
  /* height: 300px; */
  display: grid;
  place-items: center;
}
.item p {
  margin: auto;
  width: 75%;
  margin-bottom: 50px;
}
.container {
  padding: unset;
  width: inherit;
  contain: content;
  margin: auto;
}
.carousel-inner {
  width: 100%;
  margin: auto;
  height: 650px;
}

.carousel-indicators li {
  position: relative;
  top: 10px;
  width: 6px;
  height: 6px;
  margin: 8px;
  text-indent: 0;
  border: none;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  transition: 200ms;
  transition-timing-function: ease-in-out;
}
.carousel-indicators .active {
  background-color: transparent;
  border: 2px solid #fff;
  width: 8px;
  height: 8px;
}
h3 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 100;
  font-family: Oswald;
}
h2 {
  font-size: 40px;
  margin-top: 100px;
  margin-bottom: 100px;
  font-weight: 700;
  font-family: Oswald;
}
@media screen and (max-width: 720px) {
  .item {
    padding-top: 100px;
  }
  .Text {
    background-color: #23232370;
    color: #fff;
    width: 95%;
  }
  .item p {
    margin: auto;
    width: 90%;
    margin-bottom: 50px;
    font-size: 15px;
  }
  .carousel-control {
    visibility: hidden;
  }
}
</style>