<template>
  <div>
    <div class="sections">
      <img :src="icons" alt="" />
      <h2>{{ title }}</h2>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icons: String,
    title: String,
    description:String
  },
};
</script>

<style scoped>
img{
    width: 50px;
}
h2 {
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  font-family: Oswald;
}
p{
    font-size: 16px;
    line-height: 30px;
}
</style>