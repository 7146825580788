<template>
  <div>
    <button @click="navigate()">{{ buttonText }}</button>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: String,
  },
  methods: {
    navigate() {
      this.$emit("nagigation");
    },
  },
};
</script>

<style scoped>
button {
  cursor: pointer;
  border: 1px solid black;
  padding: 8px 20px 8px 20px;
  border-radius: 50px;
  background-color: transparent;
  font-size: 14px;
  color: black;
  transition: 400ms;
  transition-timing-function: ease-in-out;
}
button:hover {
  background-color: black;
  color: white;
}
</style>