<template>
  <div>
    <div id="mySidepanel" class="sidepanel">
      <div class="mobile_menu">
        <div class="Close">
          <a href="javascript:void(0)" class="closebtn" @click="closeNav()"
            >×</a
          >
        </div>
        <div @click="closeNav()"><router-link to="/">Home</router-link></div>
        <div @click="closeNav()">
          <router-link to="/spaces">Spaces for Rent</router-link>
        </div>
        <div @click="closeNav()">
          <router-link to="/services">Services & Facilities</router-link>
        </div>
        <div @click="closeNav()">
          <router-link to="/about">About</router-link>
        </div>
        <div @click="closeNav()">
          <router-link to="/contact">Contact</router-link>
        </div>
        <div class="SocialIcons">
          <a href="https://www.facebook.com/rockcitymalltz/" target="_blank"
            ><img :src="facebook" alt=""
          /></a>
          <a href="https://www.instagram.com/rockcitymalltz/" target="_blank"
            ><img :src="instagram" alt=""
          /></a>
          <a href="https://twitter.com/RockCityMall" target="_blank"
            ><img :src="twitter" alt=""
          /></a>
          <a
            href="https://www.youtube.com/channel/UC2aJaFt7NvSkf61TYY2dDHw?disable_polymer=true"
            target="_blank"
            ><img :src="youtube" alt=""
          /></a>
          <a
            href="https://www.tripadvisor.com/Attraction_Review-g678707-d12007597-Reviews-Rock_City_Mall-Mwanza_Mwanza_Region.html"
            target="_blank"
            ><img :src="tripAdvisor" alt=""
          /></a>
        </div>
      </div>
    </div>

    <nav class="navbar navbar-inverse">
      <div class="container-fluid">
        <div class="navbar-header">
          <div>
            <button @click="openNav()" type="button" class="navbar-toggle">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div class="brandName" @click="closeNav()">
            <router-link to="/"><img :src="logo" alt="" /></router-link>
          </div>
        </div>
        <div class="collapse navbar-collapse">
          <div class="Navigations">
            <ul class="nav navbar-nav">
              <li><router-link to="/">Home</router-link></li>
              <li><router-link to="/spaces">Spaces for Rent</router-link></li>
              <li>
                <router-link to="/services">Services & Facilities</router-link>
              </li>
              <li><router-link to="/about">About</router-link></li>
              <li><router-link to="/contact">Contact</router-link></li>
            </ul>
          </div>
          <div class="SocialIcons">
            <a href="https://www.facebook.com/rockcitymalltz/" target="_blank"
              ><img :src="facebook" alt=""
            /></a>
            <a href="https://www.instagram.com/rockcitymalltz/" target="_blank"
              ><img :src="instagram" alt=""
            /></a>
            <a href="https://twitter.com/RockCityMall" target="_blank"
              ><img :src="twitter" alt=""
            /></a>
            <a
              href="https://www.youtube.com/channel/UC2aJaFt7NvSkf61TYY2dDHw?disable_polymer=true"
              target="_blank"
              ><img :src="youtube" alt=""
            /></a>
            <a
              href="https://www.tripadvisor.com/Attraction_Review-g678707-d12007597-Reviews-Rock_City_Mall-Mwanza_Mwanza_Region.html"
              target="_blank"
              ><img :src="tripAdvisor" alt=""
            /></a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../assets/rock_city_mall_logo.webp"),
      facebook: require("../assets/socialmedia/Facebook.webp"),
      instagram: require("../assets/socialmedia/Instagram.webp"),
      twitter: require("../assets/socialmedia/Twitter.webp"),
      youtube: require("../assets/socialmedia/YouTube.webp"),
      tripAdvisor: require("../assets/socialmedia/Tripadvisor.webp"),
    };
  },
  methods: {
    openNav() {
      document.getElementById("mySidepanel").style.height = "100%";
    },
    closeNav() {
      document.getElementById("mySidepanel").style.height = "0";
    },
  },
};
</script>

<style scoped>
.navbar-nav li a.router-link-exact-active {
  color: #5e5e5e;
}
.Navigations {
  width: 100%;
  display: grid;
  place-items: center;
}
.navbar-toggle {
  margin: unset;
  position: absolute;
  right: 48%;
  top: 120px;
}
.navbar-inverse .navbar-toggle .icon-bar {
  color: black;
  border-color: none;
  background-color: black;
}
.navbar-inverse .navbar-toggle:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.navbar-header {
  margin: unset;
}
.collapse .SocialIcons {
  position: absolute;
  top: 130px;
  right: 20px;
  display: flex;
}
.SocialIcons a {
  padding: unset;
  margin-left: 5px;
  margin-right: 5px;
}
.SocialIcons img {
  width: 24px;
  height: 24px;
  margin: unset;
}
.navbar-inverse {
  background-color: #ffffff;
  border: none;
  height: 150px;
}
nav .container-fluid {
  display: block;
  width: 100%;
  margin: unset;
  padding: unset;
}
.navbar-header {
  width: 100%;
}

.collapse {
  padding: unset;
}
.navbar-nav li a {
  font-size: 12px;
  color: #000;
  text-decoration: none;
}
.navbar-nav li a:hover {
  font-size: 12px;
  color: #000;
  text-decoration: none;
}
.navbar-nav li a:active {
  color: #000;
}

img:hover {
  cursor: pointer;
}

.sidepanel {
  width: 100%;
  position: fixed;
  z-index: 1000;
  height: 0;
  top: 0;
  right: 0;
  background-color: #006ed8;
  overflow-x: hidden;
  transition: 0.5s;
  display: grid;
  place-items: center;
}

.sidepanel a {
  text-align: center;
  font-weight: 500;
  padding: 8px 8px 8px 24px;
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  display: block;
  transition: 0.3s;
  cursor: pointer;
}

.sidepanel .SocialIcons a {
  padding: unset;
  display: flex;
  float: right;
}
.sidepanel .SocialIcons {
  display: flex;
}

.Close a {
  font-weight: unset;
  font-size: 45px;
  padding-bottom: 30px;
  padding-left: 20px;
  transition: 100ms;
  transition-timing-function: ease-in-out;
}
.Close a:hover {
  color: #1f1e1e;
  font-size: 43px;
}
.sidepanel .closebtn {
  padding: 0px;
  position: absolute;
  top: 0;
  z-index: 1000;
  left: 5px;
}

@media only screen and (max-width: 380px) {
  .navbar-toggle {
    right: 43%;
  }
}
</style>