<template>
  <div>
    <div class="SpaceForRent">
      <h1>Gallery</h1>
      <p>{{ paragraph }}</p>
      <Button :buttonText="homebutton" @nagigation="handleAbout" />
      <div class="grid">
        <div class="Table" v-for="detail in details" :key="detail.id">
          <img :src="detail.image" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../layouts/Button";
export default {
  components: {
    Button,
  },
  data() {
    return {
      details: [
        {
          image: require("../../assets/backgroundImages/rockcity-mall-198.jpg"),
        },
        {
          image: require("../../assets/backgroundImages/rockcity mall-245.jpg"),
        },
        {
          image: require("../../assets/backgroundImages/rockcity mall-8.jpg"),
        },
        {
          image: require("../../assets/backgroundImages/rockcity mall-57.jpg"),
        },
        {
          image: require("../../assets/backgroundImages/rockcity mall-114.jpg"),
        },
          {
            image: require("../../assets/backgroundImages/rockcity mall-191.jpg"),
          },
        {
          image: require("../../assets/backgroundImages/rockcity-mall-88.jpg"),
        },
          {
            image: require("../../assets/backgroundImages/rockcity mall-35.jpg"),
          },
        {
          image: require("../../assets/backgroundImages/rockcity mall-54.jpg"),
        },
        {
          image: require("../../assets/backgroundImages/rockcity mall-54.jpg"),
        },
        // ##########################
        {
          image: require("../../assets/Gallery/Rockcitymall_new.jpg"),
        },
        {
          image: require("../../assets/Gallery/Rockcitymall_new-2.jpg"),
        },
        {
          image: require("../../assets/Gallery/Rockcitymall_new-3.jpg"),
        },
        {
          image: require("../../assets/Gallery/Rockcitymall_new-4.jpg"),
        },
        {
          image: require("../../assets/Gallery/Rockcitymall_new-5.jpg"),
        },
        {
          image: require("../../assets/Gallery/Rockcitymall_new-6.jpg"),
        },
        {
          image: require("../../assets/Gallery/Rockcitymall_new-7.jpg"),
        },
        {
          image: require("../../assets/Gallery/Rockcitymall_new-8.jpg"),
        },
        {
          image: require("../../assets/Gallery/Rockcitymall_new-9.jpg"),
        },
        {
          image: require("../../assets/Gallery/Rockcitymall_new-10.jpg"),
        },
      ],
      paragraph:
        "The Rock City Mall puts Mwanza on the Map, its an iconic location where everyone who goes through the City must come to. Its a place where you can find all necessary services under one roof.",
      homebutton: "View All",
    };
  },
  methods: {
    handleAbout() {
      this.$router.push("/about");
    },
  },
};
</script>

<style scoped>
img {
  height: 100%;
}
.grid {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 100px;
}
.Table {
  flex: 1 0 250px;
  height: 250px;
  width: inherit;
  margin: 10px;
}
h1 {
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: 700;
}
.SpaceForRent p {
  width: 80%;
  font-size: 16px;
  margin: auto;
  margin-bottom: 100px;
}
@media screen and (max-width: 500px) {
  .Table {
  height: 200px;
}
}
</style>