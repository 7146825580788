<template>
  <div >
    <div>
        <h2>Help Stop Coronavirus</h2>
    </div>
    <div id="CoronaPage">
      <Icons
        class="CoronaSection"
        v-for="detail in details"
        :key="detail.id"
        :icons="detail.icon"
        :title="detail.title"
        :description="detail.description"
      />
    </div>
  </div>
</template>

<script>
import Icons from "../layouts/Icons";
export default {
  components: {
    Icons,
  },
  data() {
    return {
      details: [
        {
          title: "STAY home as much as you can",
          description: "Stay home if you feel unwell.",
          icon: require("../../assets/icons/house.svg"),
        },
        {
          title: "KEEP a safe distance",
          description:
            "Maintain a safe distance from anyone who is coughing or sneezing.",
          icon: require("../../assets/icons/distance.svg"),
        },
        {
          title: "WASH hands often",
          description:
            "Clean your hands often. Use soap and water, or an alcohol-based hand rub.",
          icon: require("../../assets/icons/washing-hands.svg"),
        },
        {
          title: "COVER your cough",
          description:
            "Cover your nose and mouth with your bent elbow or a tissue when you cough or sneeze.",
          icon: require("../../assets/icons/cough.svg"),
        },
      ],
    };
  },
};
</script>

<style scoped>
#CoronaPage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.CoronaSection {
  flex: 1 1 250px;
  margin: 30px;
}
h2 {
  font-size: 40px;
  margin-top: 100px;
  margin-bottom: 100px;
  font-weight: 700;
  font-family: Oswald;
}
</style>