<template>
  <div id="footer">
    <h2>Rock City Mall</h2>
   <!-- <h2>Subscribe Form</h2>
     <form >
      <table>
        <tr>
          <td><input type="text" placeholder="Email Address" /></td>
        </tr>
        <tr>
          <td><button>Subscribe</button></td>
        </tr>
      </table>
    </form> -->

      <div class="contacts">
          <p><a href="mailto:info2019@rockcitymallmwanza.co.tz">info2019@rockcitymallmwanza.co.tz</a></p>
        <p>0745171715</p>
        <p>Ilemela Mwanza</p>
      </div>

    <div class="SocialIcons">
      <a href="https://www.facebook.com/rockcitymalltz/" target="_blank"
        ><img :src="facebook" alt=""
      /></a>
      <a href="https://www.instagram.com/rockcitymalltz/" target="_blank"
        ><img :src="instagram" alt=""
      /></a>
      <a href="https://twitter.com/RockCityMall" target="_blank"
        ><img :src="twitter" alt=""
      /></a>
      <a
        href="https://www.youtube.com/channel/UC2aJaFt7NvSkf61TYY2dDHw?disable_polymer=true"
        target="_blank"
        ><img :src="youtube" alt=""
      /></a>
      <a
        href="https://www.tripadvisor.com/Attraction_Review-g678707-d12007597-Reviews-Rock_City_Mall-Mwanza_Mwanza_Region.html"
        target="_blank"
        ><img :src="tripAdvisor" alt=""
      /></a>
    </div>
    <div class="date">{{date}}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("../assets/rock_city_mall_logo.webp"),
      facebook: require("../assets/socialmedia/Facebook.webp"),
      instagram: require("../assets/socialmedia/Instagram.webp"),
      twitter: require("../assets/socialmedia/Twitter.webp"),
      youtube: require("../assets/socialmedia/YouTube.webp"),
      tripAdvisor: require("../assets/socialmedia/Tripadvisor.webp"),

      date:"©"+this.getYear()+" by Rock City Mall."
    };
  },
  methods: {
    getYear(){
      var date = new Date();
      var year = date.getFullYear();
      return year
      
    }
  },
};
</script>

<style scoped>
#footer {
  width: 100%;
  font-size: 14px;
  padding-bottom:30px;
}
input {
  height: 40px;
  width: 100%;
  border:none;
  border-bottom:1px solid #000;
}
input:hover {
  border:1px solid #000;
}
button {
  margin-top: 10px;
  border: none;
  color: #fff;
  background-color: #000;
  height: 40px;
  width: 100%;
  cursor: pointer;
}
table {
  margin: auto;
  width: 400px;
}
table td {
  width: 100%;
}
table tr {
  width: 100%;
}
form{
    margin: 0px 0px 50px 0px;
}

.contacts a{
    text-decoration:none;
    color:black;
}

.contacts :nth-child(2){
    margin:10px;
}

.SocialIcons {
  width: 200px;
  margin: auto;
  display: flex;
}
.SocialIcons a {
  padding: unset;
  margin-left: 5px;
  margin-right: 5px;
  margin: auto;
}
.SocialIcons img {
  width: 24px;
  height: 24px;
  margin: unset;
}
h2{
  font-family: 'Oswald';
}
.date{
  margin-top:50px ;
}
</style>