<template>
  <div class="SpaceForRent">
    <h1>Spaces For Rent</h1>
    <p>We offer multifunctional spaces to lease and rent</p>
    <div class="grid">
      <div class="Table" v-for="detail in details" :key="detail.id">
        <img :src="detail.image" alt="" />
        <div class="Text">
          <h2>{{ detail.Header }}</h2>
          <Button :buttonText="detail.homebutton" @nagigation="handleSpace" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../layouts/Button";

export default {
  components: {
    Button,
  },
  data() {
    return {
      details: [
        {
          image: require("../../assets/backgroundImages/rockcity mall-14.webp"),
          Header: "Function Halls",
          homebutton: "Learn More",
        },
        {
          image: require("../../assets/backgroundImages/rockcity mall-56.webp"),
          Header: "Office Spaces",
          homebutton: "Learn More",
        },
        {
          image: require("../../assets/backgroundImages/rockcity mall-47.webp"),
          Header: "Dining & Restaurants",
          homebutton: "Learn More",
        },
        {
          image: require("../../assets/backgroundImages/rockcity mall-82.webp"),
          Header: "Shops",
          homebutton: "Learn More",
        },
      ],
    };
  },
  methods: {
    handleSpace() {
      this.$router.push("/spaces");
    },
  },
};
</script>

<style scoped>
img {
  height: inherit;
}
.Text {
  height: 40%;
}
.grid {
  display: flex;
  flex-wrap: wrap;
}
.Table {
  flex: 1 1 150px;
  /* height: 600px; */
  margin: 10px;
}
h2 {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  font-family: Oswald;
}
h1 {
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: 700;
}
.SpaceForRent p {
  padding: 0px 20px 0px 20px;
  font-size: 16px;
  margin-bottom: 100px;
}
@media screen and (max-width: 500px) {
  img {
    height: 70vw;
  }
  .grid {
    width: 100%;
  }
}
</style>