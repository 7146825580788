<template>
  <div id="app">
    <Header />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
import Header from "./components/Header"
export default {
  components:{
    Header,
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
*{
  margin:0;
  
}
#nav a.router-link-exact-active {
  color: #333333;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 500ms;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-action {
  opacity: 0.2;
}
</style>
