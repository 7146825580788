<template>
  <div class="home">
    <div class="homeText">
      <div>
        <h1>Rockcity Mall</h1>
        <p>Shopping & more</p>
        <div>
          <Button :buttonText="homebutton" @nagigation="handleAbout" />
        </div>
      </div>
    </div>
    <HomeSlider />
    <UpdateSlide />
    <Coronapage />
    <SpaceForRent />
    <Highlights />
    <Gallery />
    <Testimonials />
    <Footer />
  </div>
</template>

<script>
import HomeSlider from "@/components/Homefiles/HomeSlider";
import Testimonials from "@/components/Homefiles/Testimonials";
import Gallery from "@/components/Homefiles/Gallery";
import Highlights from "@/components/Homefiles/Highlights";
import SpaceForRent from "@/components/Homefiles/SpaceForRent";
import Coronapage from "@/components/Homefiles/Coronapage";
import UpdateSlide from "@/components/Homefiles/UpdateSlide";
import Button from "@/components/layouts/Button";
import Footer from "../components/Footer";
export default {
  name: "Home",
  components: {
    HomeSlider,
    Footer,
    UpdateSlide,
    Coronapage,
    SpaceForRent,
    Highlights,
    Gallery,
    Testimonials,
    Button,
  },
  data() {
    return {
      homebutton: "Learn More",
    };
  },
  methods: {
    handleAbout() {
      this.$router.push("/about");
    },
  },
};
</script>
<style>
.home {
  height: 80vh;
  position: relative;
}
.homeText {
  position: absolute;
  right: 0;
  z-index: 1;
  width: 50%;
  height: inherit;
  background-color: rgba(255, 255, 255, 0.5);
  display: grid;
  place-items: center;
  contain: content;
}
.homeText :nth-child(2) {
  margin: 50px 0px 50px 0px;
}
h1 {
  font-size: 70px;
  font-family: "Oswald";
  font-weight: 700;
}
@media screen and (max-width: 720px) {
  .homeText {
    width: 100%;
    height: 80vh;
  }
  h1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 1000px) {
  .homeText {
    height: 80vh;
  }
}
</style>