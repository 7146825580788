<template>
  <div>
    <div class="SpaceForRent">
      <h1>Highlights</h1>
      <p>Everything you need, under one roof</p>
      <div class="grid">
        <div class="Table" v-for="detail in details" :key="detail.id">
          <img :src="detail.image" alt="" />
          <h2>{{ detail.Header }}</h2>
        </div>
      </div>
    </div>
    <Button :buttonText="homebutton" @nagigation="handleServices" />
  </div>
</template>

<script>
import Button from "../layouts/Button";
export default {
  components: {
    Button,
  },
  data() {
    return {
      details: [
        {
          image: require("../../assets/highlights/Fashion & Apparel.webp"),
          Header: "Function Halls",
        },
        {
          image: require("../../assets/highlights/Cinema & Entertainment.webp"),
          Header: "Office Spaces",
        },
        {
          image: require("../../assets/highlights/Bars & Restaurants.webp"),
          Header: "Dining & Restaurants",
        },
        {
          image: require("../../assets/highlights/Banks & Supermarkets.webp"),
          Header: "Shops",
        },
      ],
      homebutton: "Learn More",
    };
  },
  methods: {
    handleServices() {
      this.$router.push("/services");
    },
  },
};
</script>

<style scoped>
.grid {
  display: flex;
  flex-wrap: wrap;
}
.Table {
  position: relative;
  flex: 1 1 150px;
  height: 600px;
  margin: 5px;
}
h2 {
  font-size: 30px;
  font-weight: 700;
  font-family: Oswald;
}
h1 {
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: 700;
}
.SpaceForRent p {
  font-size: 16px;
  margin-bottom: 100px;
}
</style>