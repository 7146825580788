<template>
  <div>
    <div>
      <h2>What Our Clients Are Saying</h2>
    </div>
    <div class="container">
      <div id="myCarousel-1" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
          <li data-target="#myCarousel-1" data-slide-to="0" class="active"></li>
          <li data-target="#myCarousel-1" data-slide-to="1"></li>
          <li data-target="#myCarousel-1" data-slide-to="2"></li>
          <li data-target="#myCarousel-1" data-slide-to="3"></li>
          <li data-target="#myCarousel-1" data-slide-to="4"></li>
          <li data-target="#myCarousel-1" data-slide-to="5"></li>
        </ol>

        <div class="carousel-inner">
          <div class="item active">
            <div class="Text">
              <div>
                <p>{{ Slide1.header }}</p>
                <p>
                  {{ Slide1.description }}
                </p>
                <p>
                  {{ Slide1.name }}
                </p>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="Text">
              <div>
                <p>{{ Slide2.header }}</p>
                <p>
                  {{ Slide2.description }}
                </p>
                <p>
                  {{ Slide2.name }}
                </p>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="Text">
              <div>
                <p>{{ Slide3.header }}</p>
                <p>
                  {{ Slide3.description }}
                </p>
                <p>
                  {{ Slide3.name }}
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="Text">
              <div>
                <p>{{ Slide4.header }}</p>
                <p>
                  {{ Slide4.description }}
                </p>
                <p>
                  {{ Slide4.name }}
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="Text">
              <div>
                <p>{{ Slide5.header }}</p>
                <p>
                  {{ Slide5.description }}
                </p>
                <p>
                  {{ Slide5.name }}
                </p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="Text">
              <div>
                <p>{{ Slide6.header }}</p>
                <p>
                  {{ Slide6.description }}
                </p>
                <p>
                  {{ Slide6.name }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <a class="left carousel-control" href="#myCarousel-1" data-slide="prev">
          <span class="glyphicon"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              /></svg
          ></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="right carousel-control"
          href="#myCarousel-1"
          data-slide="next"
        >
          <span class="glyphicon"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              /></svg
          ></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
    <div class="buttons">
      <div>
        <a
          class="ViewBtn"
          href="https://www.tripadvisor.com/Attraction_Review-g678707-d12007597-Reviews-Rock_City_Mall-Mwanza_Mwanza_Region.html"
          target="_blank"
        >
          View more on Trip Advisor
        </a>
      </div>
      <div>
        <Button
          class="getInTouchBtn"
          :buttonText="testimonialbutton"
          @nagigation="handleContact"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../layouts/Button.vue";
export default {
  components: { Button },
  name: "Slides",
  data() {
    return {
      Slide1: {
        header: "Great Mall & Delicious Eats",
        description:
          "Great air-conditioned mall to retreat from the heat waves for an hour or two. Fun grocery store/pharmacy inside the mall, perfect for purchasing waters and ice cream treats. We ate a lovely lunch at Cafe Mambo Espresso Bar & Bakery. The ginger tea is the BEST and the food is excellent!",
        name: "Rolynnie",
      },
      Slide2: {
        header: "Cask Bar and Grill- A great place to meet and eat",
        description:
          "I was at a wedding in Mwanza at the weekend 04/Nov/18. The day after our driver suggested going to a great place that he knew. The idea was sightseeing and a cold drink. I bought lunch for everyone in our group. To the locals, the prices are a bit high, but to British and Americans the prices were great and the quality and quantity were great for the price paid. The main course with fries or pasta plus 2 cold drinks each for only £7.00 / $10.00 per head.",
        name: "Bill Mackie",
      },
      Slide3: {
        header: "Good mall for groceries shopping...",
        description:
          "Good grocery shopping on the ground floor and great cinema with two saloons on the last floor. You will find a number of banks ATM.",
        name: "Maryam",
      },
      Slide4: {
        header: "Amazing to find this mall in Mwanza",
        description:
          "Considering the state of Mwanza, it is terrific to find a mall like this. Plenty of stores and good people with effective security. Locals are very proud of this mall... With good reason.",
        name: "Jens H",
      },
      Slide5: {
        header: "Has Potential!",
        description:
          "As an ex-pat living in Mwanza, I frequent this mall. Good grocery shopping, and a great cinema. Every phone and internet company is here and you can also buy household furniture and appliances and clothes. Still, a lot of empty store areas so it's exciting to see where this mall will be in the future!",
        name: "Rachel W",
      },
      Slide6: {
        header: "Great cinema",
        description:
          "We went to a 3D movie at the cinema and it was really good. I guess the mall is relatively new so everything is in good condition so far. The cinema itself has comfortable seats and reasonable prices. If you are longing for a movie, go there!",
        name: "Sebastian L",
      },
      testimonialbutton: "Get in Touch",
    };
  },
  methods: {
    handleContact() {
      this.$router.push("/contact");
    },
  },
};
</script>

<style scoped>
.carousel-control {
  opacity: unset;
  background-image: none;
}
.glyphicon {
  margin-top: 25vh;
  box-shadow: none;
}
svg {
  width: 32px;
  height: 32px;
  color: #000;
}
svg:hover {
  color: rgb(124, 124, 124);
}
a {
  height: 0px;
}
.item {
  padding-top: 180px;
  padding-bottom: 180px;
  height: 100%;
  line-height: 30px;
  text-align: center;
  margin: auto;
}
.Text {
  margin: auto;
  width: 70%;
  display: grid;
  place-items: center;
  text-align: left;
}
.Text div {
  width: 100%;
}

.container {
  padding: unset;
  width: inherit;
  contain: content;
  margin: auto;
}
.carousel-inner {
  width: 100%;
  margin: auto;
  height: 520px;
}

.carousel-indicators li {
  position: relative;
  top: 10px;
  width: 6px;
  height: 6px;
  margin: 8px;
  text-indent: 0;
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: 200ms;
  transition-timing-function: ease-in-out;
}
.carousel-indicators .active {
  background-color: transparent;
  border: 2px solid rgb(0, 0, 0);
  width: 8px;
  height: 8px;
}
h2 {
  font-size: 40px;
  margin-top: 100px;
  font-weight: 700;
  font-family: Oswald;
}
a.ViewBtn {
  margin-right: 20px;
  height: 38px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid black;
  padding: 10px 22px 12px 22px;
  border-radius: 50px;
  background-color: #000;
  font-size: 14px;
  color: rgb(255, 255, 255);
  transition: 400ms;
  transition-timing-function: ease-in-out;
  display: grid;
  place-items: center;
}
a.ViewBtn:hover {
  background-color: rgb(90, 90, 90);
  border: 1px solid rgb(90, 90, 90);
  /* color: white; */
}
.buttons {
  display: flex;
  margin: auto;
  margin-top: 20px;
  width: 380px;
}
@media screen and (max-width: 720px) {
  .buttons {
    display: block;
  }
  .item {
    padding-top: 50px;
  }
  .buttons {
    width: 250px;
  }
  .getInTouchBtn {
    margin-top: 10px;
  }
  .Text {
    width: 80%;
  }
}
</style>